//--------------------------------------------------------------------
//Typography
//--------------------------------------------------------------------

@font-face {
  font-family: "Sarabun-regular";
  src: url(../fonts/Sarabun-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Sarabun-light";
  src: url(../fonts/Sarabun-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Sarabun-thin";
  src: url(../fonts/Sarabun-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Sarabun-bold";
  src: url(../fonts/Sarabun-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Sarabun-medium";
  src: url(../fonts/Sarabun-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Sarabun-medium";
  src: url(../fonts/Sarabun-Medium.ttf) format("truetype");
}
