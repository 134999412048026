@use "../../assets/styleUtils/index" as *;

.pd_step_movement {
  .cover_step {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .each_step {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      p {
        position: absolute;
        font-weight: 500;
        font-size: 16px;
        line-height: 14px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #d7d8da;
        top: 50px;
        width: 80px;
        height: 20px;
        align-items: center;
        display: flex;
        justify-content: center;
        &.active {
          top: 56px;
        }

        &.green {
          color: var(--brand_green);
        }
      }
    }
    .line {
      height: 2px;
      width: 191px;
      // width: calc(100%/3 - 15rem);
      background-color: var(--brand_green);
      @include mq(668px) {
        width: 120px;
      }
    }
  }
}
