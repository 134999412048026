@use "../../assets/styleUtils/index" as *;

.pd_navbar {
  width: 100%;
  box-shadow: 0px 15px 50px rgba(8, 15, 52, 0.05);
  background: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  .logo {
    width: 95px;
  }
  .cover_nav {
    width: 80%;
    margin: auto;
    padding: 18px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .nav_list {
      display: flex;
      li {
        list-style: none;
        margin-right: 50px;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        a {
          text-decoration: none;
          color: var(--darkest_gray);
          font-weight: 400;
          font-size: 16px;
          // margin-right: 50px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .add {
        display: none;
        @include mq(768px) {
          display: block;
          a {
            color: white;
          }
        }
      }
      @include mq(768px) {
        @include mob_nav;
        &.nav-opened {
          clip-path: inset(0 0 0 0);
        }
      }
    }
    .cover_log {
      background: var(--brand_green);
      padding: 12px 15px;
      border-radius: 8px;
      color: var(--white);
      a {
        font-weight: 100;
        font-size: 14px;
        text-decoration: none;
        color: var(--white);
      }
      @include mq(768px) {
        display: none;
      }
    }
  }
}
