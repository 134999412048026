@use "./variables" as *;

// flex mixin
@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

// media queries
@mixin mq($arg) {
  @media screen and (max-width: $arg) {
    @content;
  }
}

@mixin mq2($arg) {
  $size: map-get(
    $map: $breakpoint,
    $key: $arg,
  );

  @media only screen and (max-width: $size) {
    @content;
  }
}

// webkit scrollbar mixins
@mixin webkit {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #f0f2f3;
  }

  &::-webkit-scrollbar {
    height: 4px;
    width: 0px;
    background: var(--brand_green);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: var(--brand_green);
  }
}

@mixin webkit2 {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: #f0f2f3;
    background: #c4c4c4;
    border-radius: 30px;
  }

  &::-webkit-scrollbar {
    height: 0px;
    width: 4px;
    background: var(--background_white);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background: var(--background_white);
  }
}

@mixin whiteContainer {
  background: var(--white);
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.08);
  border-radius: 9px;
  width: 100%;
  padding: 30px 0px 60px 0px;
}
@mixin modalLay {
  background: var(--white);
  border-radius: 11px;
  padding: 1.5rem 30px;
  margin: auto;
  max-height: 90vh;
  overflow-y: auto;
}

@mixin search {
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 21px;
  width: 250px;
  font-size: 14px;
  line-height: 21px;
  padding: 7px 10px;
  &::placeholder {
    font-size: 14px;
    line-height: 21px;
    color: var(--placeholder);
    font-family: "Sarabun", sans-serif;
  }
}

@mixin footerform {
  width: 100%;
  background: var(--white);
  border-radius: 10px;
  display: flex;
  color: #8693a5;
  height: 52px;
  justify-content: space-between;
  margin-bottom: 21px;
  border: 0.5px solid #9c9c9c;
  input {
    border: none;
    height: 100%;
    padding: 0 10px;
    flex: auto;
    background: none;
    &::placeholder {
      color: var(--placeholder);
      font-family: "Sarabun", sans-serif;
    }
  }
  button {
    background: var(--brand_green);
    border-radius: 10px;
    height: 100%;
    border: none;
    font-weight: 500;
    padding: 0 30px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--white);
    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
      background-color: var(--brand_green);
      color: white;
    }
    :hover {
      background-color: var(--brand_green);
      color: white;
    }
  }
}

@mixin mob_nav {
  flex-direction: column;
  // width: 200px;
  position: absolute;
  inset: 0;
  box-shadow: 0px 8px 25px rgba(13, 10, 44, 0.06);
  top: 72px;
  right: 0;
  left: unset;
  background-color: var(--brand_green);
  color: white;
  height: max-content;
  align-items: center;
  z-index: 20;
  padding: 0 1.5rem;
  justify-content: center;
  transition: clip-path 0.4s ease;
  clip-path: inset(0 0 100% 0);
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba($color: #000000, $alpha: 0.5);

  padding: 8px 20px;
  // padding-bottom: 30px;
  // padding-top: 0;

  li {
    margin: 0;
    margin-bottom: 20px;
    // &:first-child {
    //   margin-top: 20px;
    // }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .selectLang {
    margin: 30px 0;
  }
  .btnDiv {
    margin-bottom: 40px;
  }
}
