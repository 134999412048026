@use "../../../../assets//styleUtils/index" as *;
.pd_footer {
  background: var(--white);
  padding: 30px 0 40px;
  .footer_flex {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: auto;
    flex-wrap: wrap;
    align-items: flex-start;
    row-gap: 2rem;

    .each {
      // width: 24%;
      @include mq(868px) {
        margin-bottom: 20px;
        width: 100%;
      }
      &.logoeach {
        transform: translateY(-10px);
      }
      form {
        @include footerform;
      }
      .update {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* or 162% */

        letter-spacing: -0.02em;

        /* White */

        color: var(--medium_gray);
      }
      .logo {
        width: 100px;
        margin-bottom: 25px;
      }
      .links {
        display: flex;
        flex-direction: column;
        div {
          cursor: pointer;
        }
        a,
        div {
          color: var(--footer_blue);
          margin-bottom: 18px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .text,
      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        /* or 175% */
        letter-spacing: -0.02em;
        /* White */
        color: var(--footer_blue);
        p {
          margin-bottom: 1rem;
        }
      }
      .text {
        margin-bottom: 18px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .section_title {
        font-weight: 700;
        font-size: 19px;
        line-height: 22px;
        color: var(--footer_blue);
        margin-bottom: 24px;
      }
      .cover_img {
        display: flex;
        margin-top: 10px;
        img {
          margin-right: 15px;
          &:last-child {
            margin-right: 10;
          }
        }
      }
    }
  }
  .line {
    border-bottom: 2px solid #ebebeb;
    width: 85%;

    margin: 50px auto 30px;
  }
  .copy {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 162% */

    text-align: center;

    /* White */

    color: var(--footer_blue);
  }

  svg {
    stroke: red;
    fill: red;
    color: red;
  }
  .custom-form-container .hs_powered-by {
    display: none;
  }
  .hub_btn {
    background: var(--brand_green);
    border-radius: 10px;
    height: 100%;
    border: none;
    font-weight: 500;
    padding: 0 30px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--white);
  }
}
