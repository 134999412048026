@use "../../assets/styleUtils/index" as *;

.pd_checkout {
  width: 100%;
  .col-gap {
    column-gap: 1rem;
  }
  .cover_checkout {
    width: 80%;
    margin: 100px auto;
    @include mq(668px) {
      width: 95%;
    }
    .address-addons {
      display: flex;
      column-gap: 3rem;

      @include mq(998px) {
        flex-direction: column-reverse;
      }
    }
    .cart-info {
      flex-basis: 45%;
      .cover_checkout {
        margin-top: 0;
      }
    }
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      color: #101828;
      margin-bottom: 25px;
    }
    .page_loading {
      display: flex;
      place-content: center;
      min-height: 30rem;
      place-items: center;
    }
    .bill_box {
      width: 85%;
      margin: auto;
      padding: 80px;
      margin-top: 70px;
      background: #ffffff;
      box-shadow: 12px 12px 36px 6px rgba(2, 2, 70, 0.08);
      border-radius: 24.2028px;
      @include mq(968px) {
        width: 100%;
      }
      @include mq(968px) {
        padding: 30px;
      }

      .additional_text {
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
        color: #8693a5;
      }
      .check_flex {
        display: flex;
        align-items: center;
        margin-top: 17px;
        .css-i4bv87-MuiSvgIcon-root {
          color: var(--brand_green);
        }
        p {
          font-weight: 600;
          font-size: 10.85px;
          line-height: 16px;
          margin-left: 20px;
          color: #344054;
          &.term {
            font-weight: 600;
            font-size: 10.85px;
            line-height: 16px;
            color: #344054;
            span {
              color: var(--brand_green);
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        &.summary_check {
          margin: 50px 0 60px;
          p {
            color: var(--darkest_gray);
            a {
              text-decoration: underline;
              color: var(--darkest_gray);
            }
          }
          .css-i4bv87-MuiSvgIcon-root {
            color: var(--darkest_gray);
          }
        }
      }
      button.btn {
        background: var(--brand_green);
        border: 0.775px solid var(--brand_green);
        box-shadow: 6.2px 6.2px 18.6px rgba(2, 2, 70, 0.15);
        border-radius: 6.2px;
        width: 170px;
        font-weight: 500;
        font-size: 12.4px;
        line-height: 19px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 39px;
        position: relative;
        img {
          margin-left: 9px;
          width: 9px;
        }
        &.pay {
          width: 100%;
        }
      }
      .submit_box {
        margin-top: 60px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        .logo {
          width: 85px;
        }
        .check_flex {
          margin-top: 0;
        }
        @include mq(998px) {
          display: block;
          button {
            width: 100%;
            margin-top: 1.5rem;
          }
        }
      }

      .btn_disabled {
        background: #a9b1bd !important;
        border-color: #a9b1bd !important;
      }

      .loader {
        position: absolute;
        right: 10px;
      }

      .each_pack {
        background: #ffffff;
        border: 0.383033px solid #b2bcca;
        border-radius: 6.12853px;
        padding: 12px 18px;
        margin-bottom: 8px;
        width: 100%;
        .top_pack {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          p {
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: var(--darkest_gray);
          }
        }
        .text_flex {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 8px;
          &.total {
            p {
              font-weight: 600;
              color: var(--darkest_gray);
              font-size: 18px;
              margin: 10px 0 10px;
            }
          }

          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: var(--darkest_gray);
            &:last-child {
              text-align: right;
            }
          }
        }
        &.sum {
          padding-bottom: 20px;
        }
      }
      .line {
        width: 100%;
        margin: 7px 0 18px;
        border-bottom: 0.7px solid #e0e0e0;
      }
      .logo.sum {
        width: 50px;
        margin: 23px auto;
        display: block;
      }
    }
  }
}

.back_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.back {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #101828;
  column-gap: 1rem;
}

.back_icon {
  margin-right: 8px;
  width: 15px;
}

.success {
  display: flex;
  place-items: center;
  place-content: center;
  flex-flow: column;
  font-size: 18px;
  img {
    margin-bottom: 8px;
  }
}

li {
  line-height: 1.7;
  font-size: 14px;
}

.postal_code {
  width: 100% !important;
}

.phone_class {
  width: 100% !important;
}

.back-to-checkout-btn {
  width: calc(100%) !important;
}

@media (max-width: 768px) {
  .success_container {
    width: calc(100% - 2rem) !important;
    padding: 5rem 1rem;
  }
}

@media screen and (min-width: 769px) and (max-width: 1051px) {
  .success_container {
    width: calc(100% / 1.9) !important;
    padding: 5rem 1rem;
  }
}

.back_to_web_text {
  text-decoration: underline;
  text-underline-offset: 3px;
  color:var(--brand_green)
}

.success_container {
  width: calc(100% / 2.63);
  // width: auto;
  height: fit-content;
  padding: 5rem 3rem;
  border-radius: 24px;
  // border: 0.4px solid rgba(99, 99, 99, 0.5);
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 12px 12px 36px 6px rgba(2, 2, 70, 0.08);
}
