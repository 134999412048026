@use "../../assets/styleUtils/index" as *;

.pd_feature {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  padding: 22px 30px;
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
  @include mq(768px) {
    padding: 22px 12px;
  }
  .top_side {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    justify-content: space-between;
    .left_part {
      display: flex;
      align-items: center;
      img {
        width: 2.7rem;
      }
      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: var(--darkest_gray);
        margin-left: 16px;
        @include mq(668px) {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
    p.amount {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: var(--darkest_gray);
    }
  }
  .mid_text {
    width: 70%;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: justify;
    color: var(--dark_gray);
    margin-bottom: 20px;
    @include mq(668px) {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .last_side {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq(768px) {
      align-items: center;
    }
    .left_part {
      display: flex;
      cursor: pointer;
      align-items: center;
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-align: justify;
        color: var(--brand_green);
        margin-right: 11px;
      }
    }
    button {
      font-weight: 600;
      transform: translateY(-10px);
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: var(--brand_green);
      width: 120px;
      padding: 5px 0;
      background: white;
      border: 1px solid var(--brand_green);
      border-radius: 30px;
      @include mq(768px) {
        transform: translateY(0);
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
        background-color: var(--brand_green);
        color: white;
      }
      :hover {
        background-color: var(--brand_green);
        color: white;
      }
    }
  }
}

.pd_add_on {
  padding-top: 109px;
  width: 100%;
  margin-bottom: 150px;

  .cover_add {
    width: 75%;
    margin: auto;
    @include mq(1201px) {
      width: 90%;
    }
    @include mq(768px) {
      width: 100%;
    }
    .title {
      font-weight: 500;
      font-size: 28px;
      line-height: 56px;
      /* or 56px */
      padding-left: 10px;
      color: var(--darkest_gray);
    }
  }
  .addon_flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @include mq(768px) {
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
    }
    .left {
      width: 52%;
      padding-top: 30px;
      padding-bottom: 30px;
      @include mq(768px) {
        width: 100%;
      }
      height: 53rem;
      overflow-y: scroll;

      > div {
        margin-right: auto;
        margin-left: auto;
        width: 92%;
      }
    }
    .right {
      width: 45%;
      @include mq(768px) {
        margin-top: 30px;
        width: 90% !important;
        margin-right: auto;
        margin-left: auto;
      }
      .cover_form_side {
        width: 100%;
        .details_box {
          width: 100%;
          margin-top: 20px;
          border: 1px solid #b7c3cd;
          border-radius: 5px;
          font-family: "Sarabun", sans-serif;
          padding: 25px 30px 105px;
          .text_flex {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 17px;
            .left_text,
            .right_text,
            .right_box p {
              font-weight: 500;
              font-size: 18px;
              line-height: 23px;
              color: var(--medium_gray);
              &.dark {
                color: var(--darkest_gray);
              }
            }
            .left_text {
              color: var(--text_label_black) !important;
            }
            .right_text {
              text-align: center;
              // width: 65px;
              flex-basis: 68px;
            }
            .right_box {
              display: flex;
              align-items: center;
              width: fit-content;
              justify-content: center;
              .ellipse {
                margin-left: 17px;
              }
              .arr {
                width: 10px;
                // cursor: pointer;
                &.rotate {
                  transform: rotate(180deg);
                }
              }
              .text_square {
                width: max-content;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #404a57;
                border-radius: 4px;
                padding: 0 12px;
                margin: 0 8px;
              }
            }
            .addon_name_qty {
              display: flex;
              justify-content: space-between;
              // column-gap: 2rem;
              width: 16rem;
            }
          }
          .line {
            margin: 13px 0 23px;
            width: 100%;
            border-bottom: 0.5px solid rgba(94, 106, 121, 0.4);
            &.total {
              margin: 105px 0 30px;
              height: 9rem;
              overflow-y: scroll;
            }
          }
          .summary {
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 20px;
            color: var(--medium_gray);
          }
          .add-ons-list {
            height: 10.5rem;
            // overflow-y: scroll;
          }
        }
        button {
          width: 203px;
          /* Brand Green */
          float: right;
          margin-top: 27px;
          border: none;
          background: var(--brand_green);
          border-radius: 10px;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          height: 47px;
          text-align: center;
          color: var(--white);
        }
      }
      @include mq(768px) {
        width: 100%;
      }
    }
  }
}

.delete-item {
  width: max-content;
  top: 23px;
  background: rgba(224, 224, 224, 1);
  padding: 3px 7px;
  left: -9px;
  border-radius: 5px;
  cursor: pointer;
  color: #8693a5;
  z-index: 5;
}
.delete-item::after {
  content: "";
  position: absolute;
  bottom: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(224, 224, 224, 1) transparent transparent transparent;
  transform: rotate(180deg);
  right: 70%;
}
